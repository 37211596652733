import api from '../api';

function getErrorResponse(error, reference = ''){
  return {
    success: false,
    data: null,
    message: error.message,
    errors: (error.response?.data ? error.response.data : ''),
    reference
  };
}
function getErrorResponsePromise(error, reference = ''){
  const e =  {
    success: false,
    data: null,
    message: error.message,
    errors: (error.response?.data ? error.response.data : ''),
    reference
  };
  return Promise.reject(e);
}

export default {
  createSession() {
    return api().get('/sanctum/csrf-cookie')
      .catch(getErrorResponse);
  },
  login(params) {
    return api().post('/api/login', params)
      .catch(getErrorResponsePromise);
  },
  logout() {
    return api().get('/api/logout')
      .catch(getErrorResponse);
  },
  register(params) {
    return api().post('/api/register', params)
      .catch(getErrorResponse);
  },
  getUser() {
    return api().get('/api/user')
      .catch(getErrorResponse);
  },
  getEmployee() {
    return api().get('/api/employee')
      .catch(getErrorResponse);
  },

  activateAccount(params) {
    return api().post('/api/activate-account', params)
      .catch(getErrorResponse);
  },
  resendActivationCode() {
    return api().get('/api/resend-activation-code')
      .catch(getErrorResponse);
  },
  forgotPassword(params) {
    return api().post('/api/password/forgot', params)
      .catch(getErrorResponse);
  },
  resetPassword(params) {
    return api().post('/api/password/reset', params)
      .catch(getErrorResponse);
  },
  editUser(params) {
    return api().post('/api/user', params)
      .catch(getErrorResponse);
  },
  uploadAvatar(params) {
    const apiForMultipart = api('multipart/form-data');
    return apiForMultipart.post('/api/user/avatar', params);
  },
};
