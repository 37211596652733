<template>
  <div>
    <loading :active="isLoading"
             :is-full-page="true"
             :opacity="1"
             loader="dots"
             color="#3956f5"/>
    <component :is="layout || 'div'">
      <router-view v-slot="{ Component }">
        <transition>
          <keep-alive>
            <component :is="Component"/>
          </keep-alive>
        </transition>
      </router-view>
    </component>

    <teleport to="body">
      <!--begin::Alert-->
      <div v-if="updateExists"
           class="w-100 w-lg-25 alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 rounded position-fixed"
           style="bottom: 1rem;z-index: 1000;">
        <!--begin::Close-->
        <button type="button" class="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger"
                data-bs-dismiss="alert">
          <i class="bi bi-x-circle fs-1"/>
        </button>
        <!--end::Close-->

        <!--begin::Icon-->
        <i class="bi bi-info-circle-fill fs-3tx text-danger mb-5"/>
        <!--end::Icon-->

        <!--begin::Wrapper-->
        <div class="text-center">
          <!--begin::Title-->
          <h1 class="fw-bold mb-5">È disponibile un aggiornamento</h1>
          <!--end::Title-->

          <!--begin::Separator-->
          <div class="d-none d-lg-block separator separator-dashed border-danger opacity-25 mb-5"></div>
          <!--end::Separator-->

          <!--begin::Content-->
          <div class="d-none d-lg-block mb-9 text-gray-900">
            L'app è stata aggiornata, procedi con l'aggiornamento per ottenere l'ultima versione disponibile.
          </div>
          <!--end::Content-->

          <!--begin::Buttons-->
          <div class="d-flex flex-center flex-wrap">
            <a href="#" class="btn btn-outline btn-outline-danger btn-active-danger m-2">{{ $t('buttons.cancel') }}</a>
            <a href="#" class="btn btn-danger m-2" @click="refreshApp">{{ $t('buttons.update') }}</a>
          </div>
          <!--end::Buttons-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Alert-->
    </teleport>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Loading from 'vue3-loading-overlay';
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import update from '@/mixins/update'
import router from '@/router'
import layouts from "@/layouts";
import {shallowRef, provide} from "vue";

export default {
  data() {
    return {
      login: false,
      error: false,
      layout: shallowRef('div')
    };
  },
  components: {
    Loading,
  },
  mixins: [update],
  computed: {
    ...mapGetters({
      authenticated: 'authenticated',
      isLoading: 'isLoading',
    }),
  },

  created() {
    document.documentElement.setAttribute('dir', 'ltr');
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleScroll);

    router.afterEach((to) => {
      this.layout = layouts[to.meta?.layout] || 'div';
    })
    provide('app:layout', this.layout);
  },

  mounted() {
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
      document.body.addEventListener('touchstart', () => {
      }, false);
    }
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    async logout() {
      this.$store.dispatch('setLoading', true);
      try {
        await this.$store.dispatch('logout');
        await this.$router.push({name: 'login'});
      } catch (error) {
        this.error = error;
      } finally {
        this.$store.dispatch('setLoading', false);
      }
    },

    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);
      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    },
  },
};
</script>

<style lang="scss">
</style>
