import api from '../api';

function getErrorResponse(error, reference = ''){
  throw new Error(reference + ' | ' + error.message);
}

export default {
  list(params) {
    return api()
      .get('/api/appointments', { params })
      .catch((error) => getErrorResponse(error));
  },
  get(params) {
    return api()
      .get('/api/appointments/details', { params })
      .catch((error) => getErrorResponse(error));
  },
  create(params) {
    return api()
      .post('/api/appointments/new', params)
      .catch((error) => getErrorResponse(error));
  },
  edit(params) {
    return api()
      .post('/api/appointments/edit', params)
      .catch((error) => getErrorResponse(error));
  },
  listOfTypes(params) {
    return api()
      .get('/api/appointment-types', { params })
      .catch((error) => getErrorResponse(error));
  },
  createProduct(params) {
    return api()
      .post('/api/appointments/products/new', params)
      .catch((error) => getErrorResponse(error));
  },
  editProduct(params) {
    return api()
      .post('/api/appointments/products/edit', params)
      .catch((error) => getErrorResponse(error));
  },
  close(params) {
    return api()
      .post('/api/appointments/close', params)
      .catch((error) => getErrorResponse(error));
  },
  delete(params) {
    return api()
      .get('/api/appointments/delete', { params })
      .catch((error) =>getErrorResponse(error));
  },
  getProduct(params) {
    return api()
      .get('/api/appointments/products/get', { params })
      .catch((error) => getErrorResponse(error));
  },
  deleteProduct(params) {
    return api()
      .get('/api/appointments/products/delete', { params })
      .catch((error) => getErrorResponse(error));
  },
  confirmProduct(params) {
    return api()
      .post('/api/appointments/products/confirm', params)
      .catch((error) => getErrorResponse(error));
  },
  deliveredProducts(params) {
    return api()
      .post('/api/appointments/products/delivered', params)
      .catch((error) => getErrorResponse(error));
  },
  removeDeliveredProducts(params) {
    return api()
      .post('/api/appointments/products/remove-delivered', params)
      .catch((error) => getErrorResponse(error));
  },
  uploadProductPhotos(params) {
    const apiForMultipart = api('multipart/form-data');
    return apiForMultipart.post('/api/appointments/products/photos/upload', params)
      .catch((error) => getErrorResponse(error));
  },
  addProductDiscount(params) {
    return api()
      .post('/api/appointments/products/discount', params)
      .catch((error) => getErrorResponse(error));
  },
  drop(params) {
    return api()
      .post('/api/appointments/drop', params)
      .catch((error) => getErrorResponse(error));
  },
  isAvailable(params) {
    return api()
      .get('/api/appointments/is-available', { params })
      .catch((error) => getErrorResponse(error));
  },
  fsmProduct(params) {
    return api()
      .post('/api/appointments/products/fsm', params)
      .catch((error) => getErrorResponse(error));
  },

  setOperator(params) {
    return api().post('/api/appointments/operator', params);
  },
};
