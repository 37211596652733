import repository from '@/api/repository';

export default {
  state: () => ({
    user: sessionStorage.user ? JSON.parse(sessionStorage.getItem('user')) : null,
    employee: sessionStorage.employee ? JSON.parse(sessionStorage.getItem('employee')) : null,
    employees: [],
  }),
  getters: {
    user: (state) => state.user,
    isAdmin: (state) => state.user.role === 'admin',
    employee: (state) => state.employee,
    employees: (state) => state.employees,
    authenticated: (state) => state.user !== null,
    verified: (state) => state.user !== null && state.user.email_verified_at !== null,

    hasPermissions: (state) => (pta) => {
      let permissionsToAccess = pta;
      if (!Array.isArray(pta)) permissionsToAccess = [pta];
      let permissions = [];
      if (state.employee) permissions = state.employee.role?.permissions?.permissions;
      return permissions?.some((i) => permissionsToAccess.includes(i));
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      if (user) {
        sessionStorage.user = JSON.stringify(user);
      } else {
        sessionStorage.removeItem('user');
      }
    },
    SET_EMPLOYEE(state, employee) {
      state.employee = employee;
      if (employee) {
        sessionStorage.employee = JSON.stringify(employee);
      } else {
        sessionStorage.removeItem('employee');
      }
    },
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
  },
  actions: {
    async setUser({commit, dispatch}, user) {
      commit('SET_USER', user);
      if (user) {
        if(user.last_store_id){
          dispatch('getStore', user.last_store_id)
        }
        dispatch('refreshEmployee');
      }else{
        commit('SET_EMPLOYEE', null);
        commit('SET_EMPLOYEES', []);
      }
    },
    async register({dispatch}, user) {
      await repository.auth.createSession();
      const {data} = await repository.auth.register(user);
      dispatch('setUser', data.data);
    },
    async login({dispatch}, user) {
      try {
        await repository.auth.createSession();
        const {data} = await repository.auth.login(user);
        dispatch('setUser', data.data);
      }catch (error){
        return Promise.reject(error);
      }
    },
    async logout({dispatch}) {
      await repository.auth.logout();
      dispatch('setUser', null);
      sessionStorage.clear();
    },
    async activateAccount({dispatch}, code) {
      try {
        const res = await repository.auth.activateAccount({code});
        dispatch('setUser', res.data.data);
        if (res.data.data !== null) {
          return {
            success: true,
            data: res.data.data
          };
        } else {
          return res;
        }
      } catch (error) {
        return {
          success: false,
          data: error.message,
          errors: error.response.data
        };
      }
    },
    async resendActivationCode({commit}) {
      const {data} = await repository.auth.resendActivationCode();
      commit('SET_USER', data.data);
    },
    async refreshUser({dispatch}) {
      try {
        const {data} = await repository.auth.getUser();
        dispatch('setUser', data.data);
        return data;
      } catch (error) {
        return {
          success: false,
          data: error.message,
          errors: error.errors
        };
      }
    },
    async refreshEmployee({commit}) {
      const {data} = await repository.auth.getEmployee();
      commit('SET_EMPLOYEE', data);
    },
  },
};
