import middleware from './middleware';

export default [
  {
    path: '/register',
    name: 'register',
    meta: {layout: 'AuthLayout'},
    component: () => import('@/views/auth/RegisterView.vue'),
    beforeEnter: middleware.guest,
  },
  {
    path: '/login',
    name: 'login',
    meta: {layout: 'AuthAsideLayout'},
    component: () => import('@/views/auth/LoginView.vue'),
    beforeEnter: middleware.guest,
  },
  {
    path: '/password/forgot',
    name: 'forgot_password',
    meta: {layout: 'AuthLayout'},
    component: () => import('@/views/auth/ForgotPasswordView.vue'),
    beforeEnter: middleware.guest,
  },
  {
    path: '/password/forgot/:token',
    name: 'reset_password',
    meta: {layout: 'AuthLayout'},
    props: true,
    component: () => import('@/views/auth/ResetPasswordView.vue'),
    beforeEnter: middleware.guest,
  },
  {
    path: '/verify-account',
    name: 'verify',
    meta: {layout: 'BlankLayout'},
    component: () => import('@/views/auth/AuthVerifyView.vue'),
    beforeEnter: middleware.notVerified,
  },

  /** Wizards * */
  {
    path: '/wizard',
    meta: {layout: 'WizardLayout'},
    beforeEnter: middleware.verified,
    children: [
      {
        path: '/contract/new',
        name: 'wizard-new-contract',
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/contracts/NewContractView.vue'),
      },
      {
        path: '/contracts/:contractId(\\d+)/order/:id(\\d+)',
        name: 'wizard-contract-order',
        props: true,
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/contracts/WizardContractOrderView.vue'),
      },
      {
        path: '/appointment/new/:datetime?',
        name: 'wizard-new-appointment',
        props: true,
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/appointments/NewAppointmentView.vue'),
      },
      {
        path: '/appointment/edit/:id(\\d+)/:datetime?',
        name: 'wizard-edit-appointment',
        props: true,
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/appointments/EditAppointmentView.vue'),
      },
      {
        path: '/appointment/next/:contractId(\\d+)/:datetime?',
        name: 'wizard-next-appointment',
        props: true,
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/appointments/NextAppointmentView.vue'),
      },
      {
        path: '/product/:id(\\d+)?',
        name: 'wizard-product',
        meta: {permissions: ['products']},
        props: true,
        beforeEnter: [middleware.verified, middleware.hasPermissions],
        component: () => import('@/views/platform/wizards/products/WizardProductView.vue'),
      },
      {
        path: '/supplier/order',
        name: 'wizard-supplier-order',
        meta: {permissions: ['supplier_orders']},
        props: true,
        beforeEnter: [middleware.verified, middleware.hasPermissions],
        component: () => import('@/views/platform/wizards/supplier-orders/WizardSupplierOrderView.vue'),
      },
      {
        path: '/supplier/order/edit/:id(\\d+)?',
        name: 'wizard-edit-supplier-order',
        meta: {permissions: ['supplier_orders']},
        props: true,
        beforeEnter: [middleware.verified, middleware.hasPermissions],
        component: () => import('@/views/platform/wizards/supplier-orders/EditDetailsSupplierOrderView.vue'),
      },
      {
        path: '/supplier/order/edit-products/:id(\\d+)?',
        name: 'wizard-edit-products-supplier-order',
        meta: {permissions: ['supplier_orders']},
        props: true,
        beforeEnter: [middleware.verified, middleware.hasPermissions],
        component: () => import('@/views/platform/wizards/supplier-orders/EditProductsSupplierOrderView.vue'),
      },
      {
        path: '/invoice',
        name: 'wizard-invoice',
        meta: {permissions: ['supplier_invoices']},
        props: true,
        beforeEnter: [middleware.verified, middleware.hasPermissions],
        component: () => import('@/views/platform/wizards/invoices/WizardInvoiceView.vue'),
      },
      {
        path: '/internal-ddt',
        name: 'wizard-internal-ddt',
        meta: {permissions: ['internal_ddts']},
        props: true,
        beforeEnter: [middleware.verified, middleware.hasPermissions],
        component: () => import('@/views/platform/wizards/internal-ddts/WizardInternalDdtView.vue'),
      },
      {
        path: '/interventions/:contractId(\\d+)/:productId(\\d+)?',
        name: 'wizard-intervention-create',
        props: true,
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/intervention/NewView.vue'),
      },
      {
        path: '/interventions/:id(\\d+)/edit',
        name: 'wizard-intervention-edit',
        props: true,
        beforeEnter: middleware.verified,
        component: () => import('@/views/platform/wizards/intervention/EditView.vue'),
      },
    ]
  },

  /** Platform * */
  {
    path: '/',
    meta: {layout: 'PlatformLayout'},
    beforeEnter: middleware.verified,
    children: [
      {
        path: '',
        name: 'platform',
        component: () => import('@/views/platform/DashboardView.vue'),
      },
      {
        path: 'profile',
        name: 'profile',
        component: () => import('@/views/platform/settings/ProfileSettingsView.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/platform/settings/SettingsDashboardView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'store-settings',
        name: 'store_settings',
        component: () => import('@/views/platform/settings/Store/StoreSettingsView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'store-settings/hours',
        name: 'store_hours',
        component: () => import('@/views/platform/settings/Store/StoreHoursView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'store-settings/holidays',
        name: 'store_holidays',
        component: () => import('@/views/platform/settings/Store/StoreHolidaysView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'store-settings/___imports___',
        name: 'store_imports',
        component: () => import('@/views/platform/settings/Store/StoreImportsView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'store-settings/permissions',
        name: 'role_permissions',
        component: () => import('@/views/platform/settings/Store/RolePermissionsView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'settings/categories',
        name: 'settings-categories',
        component: () => import('@/views/platform/settings/CategoriesView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'settings/product-options',
        name: 'settings-product-options',
        component: () => import('@/views/platform/settings/ProductOptionsView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      /** warehouse */
      {
        path: '/warehouse/products',
        name: 'warehouse-products',
        component: () => import('@/views/platform/warehouse/ProductsView.vue'),
        beforeEnter: middleware.hasPermissions,
        meta: {permissions: ['products']},
      },
      {
        path: '/warehouse/products/:id',
        name: 'warehouse-product',
        props: true,
        component: () => import('@/views/platform/warehouse/Details/ProductDetailsView.vue'),
        beforeEnter: middleware.hasPermissions,
        meta: {permissions: ['products']},
      },
      {
        path: '/warehouse/orders',
        name: 'warehouse-orders',
        component: () => import('@/views/platform/warehouse/OrdersView.vue'),
        beforeEnter: middleware.hasPermissions,
        meta: {permissions: ['supplier_orders']},
      },
      {
        path: '/warehouse/direct-orders',
        name: 'warehouse-direct-orders',
        component: () => import('@/views/platform/warehouse/DirectOrdersView.vue'),
        beforeEnter: middleware.hasPermissions,
        meta: {permissions: ['cart']},
      },
      {
        path: '/warehouse/ddts',
        name: 'warehouse-ddts',
        component: () => import('@/views/platform/warehouse/DDTsView.vue'),
        meta: {permissions: ['supplier_ddts']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/warehouse/internal-ddts',
        name: 'warehouse-internal-ddts',
        component: () => import('@/views/platform/warehouse/InternalDDTsView.vue'),
        meta: {permissions: ['internal_ddts']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/warehouse/invoices',
        name: 'warehouse-invoices',
        component: () => import('@/views/platform/warehouse/InvoicesView.vue'),
        meta: {permissions: ['supplier_invoices']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/warehouse/inventory',
        name: 'inventory',
        component: () => import('@/views/platform/warehouse/InventoryView.vue'),
      },
      /** End warehouse */

      {
        path: 'agenda',
        name: 'agenda',
        component: () => import('@/views/platform/agenda/CalendarView.vue'),
        meta: {permissions: ['agenda']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: 'employees',
        name: 'employees',
        component: () => import('@/views/platform/employees/EmployeesDashboardView.vue'),
        beforeEnter: middleware.isAdmin,
      },
      {
        path: 'employees/:id(\\d+)/details',
        name: 'employee-details',
        props: true,
        component: () => import('@/views/platform/employees/DetailsView.vue'),
        beforeEnter: middleware.isAdmin,
      },

      {
        path: 'suppliers',
        name: 'supplier-list',
        component: () => import('@/views/platform/suppliers/ListView.vue'),
        meta: {permissions: ['suppliers']},
        beforeEnter: middleware.hasPermissions,
      },

      {
        path: 'suppliers/:id(\\d+)/details',
        name: 'supplier-details',
        props: true,
        component: () => import('@/views/platform/suppliers/DetailsView.vue'),
        meta: {permissions: ['suppliers']},
        beforeEnter: middleware.hasPermissions,
      },

      /** Contracts */
      {
        path: '/contracts',
        name: 'contracts',
        component: () => import('@/views/platform/clients/ContractsView.vue'),
        meta: {permissions: ['contracts']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/contracts/:id(\\d+)/details',
        name: 'contracts-details',
        props: true,
        component: () => import('@/views/platform/clients/ContractView.vue'),
        meta: {permissions: ['contracts', 'agenda']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/contracts/:id(\\d+)/products',
        name: 'contracts-products',
        props: true,
        component: () => import('@/views/platform/clients/contract/ContractProductsView.vue'),
        meta: {permissions: ['contracts']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/contracts/:id(\\d+)/payments',
        name: 'contracts-payments',
        props: true,
        component: () => import('@/views/platform/clients/contract/ContractPaymentsView.vue'),
        meta: {permissions: ['contracts']},
        beforeEnter: middleware.hasPermissions,
      },
      {
        path: '/contracts/:id(\\d+)/interventions',
        name: 'contracts-interventions',
        props: true,
        component: () => import('@/views/platform/clients/contract/ContractInterventionsView.vue'),
        meta: {permissions: ['contracts']},
        beforeEnter: middleware.hasPermissions,
      },
      /** End Contracts */

      /** Tailoring */
      {
        path: '/tailoring',
        name: 'tailoring-interventions',
        component: () => import('@/views/platform/tailoring/InterventionsView.vue'),
        meta: {permissions: ['tailoring']},
        beforeEnter: middleware.hasPermissions,
      },
      /** End Tailoring */

      {
        path: '/cart',
        name: 'cart',
        component: () => import('@/views/platform/cart/CartView.vue'),
      },

      {
        path: '/:pathMatch(.*)*',
        name: 'PlatformNotFound',
        component: () => import('@/views/errors/NotFoundView.vue'),
      },
    ],
  },
];
